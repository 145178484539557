
import Vue from "vue";
import { HTTP } from "@/utils/http-common";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "index",
  props: {
    token: {
      type: String,
      required: true,
    },
  },
  data(): any {
    return {
      email: "",
      password: "",
      passwordConfirmation: "",
      serverMessage: "",
      passwordChanged: false,
      passwordInfo: null,
      passwordErrors: null,
    };
  },
  computed: {
    ...mapGetters(["userRole", "twoFactor"]),
  },
  watch: {
    password(password) {
      const isToShort = password.length < 10;
      const hasNoCapitalLetter = !password.match(/[A-Z]/g);
      const hasNoSmallLetter = !password.match(/[a-z]/g);
      const hasNoSpecialCharacter = !password.match(/[^a-zA-Z\d]/g);

      if (isToShort) {
        this.passwordInfo = "Hasło musi mieć min. 10 znaków.";
      } else if (hasNoCapitalLetter) {
        this.passwordInfo =
          "Hasło musi zawierać przynajmniej jedną wielką literę.";
      } else if (hasNoSmallLetter) {
        this.passwordInfo =
          "Hasło musi zawierać przynajmniej jedną małą literę.";
      } else if (hasNoSpecialCharacter) {
        this.passwordInfo =
          "Hasło musi zawierać przynajmniej jeden znak specjalny.";
      } else {
        this.passwordInfo = null;
      }
    },
  },
  methods: {
    resetPassword: function () {
      if (this.password.length >= 10) {
        if (this.password === this.passwordConfirmation) {
          this.dispatchNewPassword();
        } else {
          this.serverMessage = "Hasło i jego potwierdzenie nie są takie same!";
        }
      } else {
        this.serverMessage = "Hasło nie może być krótsze niż 10 znaków";
      }
    },
    dispatchNewPassword: function () {
      this.passwordErrors = null;
      this.serverMessage = null;
      HTTP.put(
        `api/user/reset/password/${this.token}`,
        {
          email: this.email,
          password: this.password,
          password_confirmation: this.passwordConfirmation,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => {
          this.serverMessage = `Nowe hasło dla konta ${this.email} zostało ustawione.`;
          this.passwordChanged = true;
        })
        .catch((error) => {
          if (error?.response?.data?.password) {
            this.passwordErrors = error.response.data.password;
          } else if (error?.response?.data?.data?.message) {
            this.serverMessage = error.response.data.data.message;
          } else {
            this.serverMessage =
              "Niepoprawne hasło lub adres e-mail nie zgadza się z wykorzystywanym w systemie.";
          }
        });
    },
  },
  mounted() {
    if (this.userRole !== "guest") {
      if (this.userRole === "administrator" && this.twoFactor) {
        this.$router.push("/admin");
      } else if (this.userRole === "administrator" && !this.twoFactor) {
        this.$router.push("/admin/mfa");
      } else {
        this.$router.push("/informacje-organizacyjne");
      }
    }

    HTTP.get("sanctum/csrf-cookie").catch((e) => {
      console.error(e);
    });
  },
});
